import { FC } from "react";
import styled from "styled-components";

const SplashContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: white;
    padding: 5rem;
`;

const Splash: FC = props => {
    return (
        <SplashContainer>
            <img src="/images/splash.webp" className="d-block m-auto" alt="Splash" />
        </SplashContainer>
    );
};

export default Splash; // using animated splash
