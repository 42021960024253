export const SITE_SLUG = process.env.REACT_APP_SITE_SLUG;
export const SITE_STORE_TYPE = process.env.REACT_APP_SITE_STORE_TYPE;
export const DEFAULT_IMAGE = process.env.REACT_APP_DEFAULT_IMAGE;
export const DEFAULT_EMAIL = process.env.REACT_APP_DEFAULT_EMAIL;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const LOGROCKET_PROJECT = process.env.REACT_APP_LOGROCKET_PROJECT;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
export const BACKEND_SERVERLESS_URL = process.env.REACT_APP_BACKEND_SERVERLESS_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
