import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { dataURLtoFile, resizeImage } from "./CanvasUtils";
import CropImage from "./CropImage";

const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    "&:hover": {
        borderColor: "#2196f3"
    }
};

const cancelButtonStyle = {
    top: "5px",
    right: "5px"
};

const activeStyle = {
    borderColor: "#2196f3"
};

const acceptStyle = {
    borderColor: "#00e676"
};

const rejectStyle = {
    borderColor: "#ff1744"
};

const previewImage = {
    maxWidth: "300px",
    maxHeight: "300px"
};

interface FileDropProps {
    selectedFile?: any;
    onSelectFile: Function;
    url?: string;
    aspect_ratio?: number;
    cropSize?: { width: number; height: number };
    upload_type?: string;
    disable_crop?: boolean;
    no_preview?: boolean;
    accepted_formats?: string | string[];
}

const FileDrop: FC<FileDropProps> = (props: FileDropProps) => {
    const [acceptedFile, setAcceptedFile] = useState(props.selectedFile);
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState(props.url);
    const [tempAcceptedFile, setTempAcceptedFile] = useState();

    useEffect(() => {
        if (props.selectedFile) {
            onSubmitCropImage(props.selectedFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedFile]);

    const onDrop = useCallback(acceptedFiles => {
        let acceptedFile = acceptedFiles[0];
        if (acceptedFile.type === "image/gif") {
            onSubmitCropImage(acceptedFile);
        } else if (props.disable_crop) {
            onSubmitCropImage(acceptedFile);
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = async () => {
                resizeImage((resizedImageDataUrl: string) => {
                    setOpen(true);
                    setTempAcceptedFile(dataURLtoFile(resizedImageDataUrl, "temp") as any);
                }, reader.result as string);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCancel = () => {
        setAcceptedFile(undefined);
        setUrl(undefined);
        props.onSelectFile(undefined);
    };

    const handleClose = () => {
        // setAcceptedFile(tempAcceptedFile);
        setOpen(false);
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ accept: props.accepted_formats ? props.accepted_formats : "image/*", onDrop });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const onSubmitCropImage = (acceptedFile: File) => {
        setAcceptedFile(acceptedFile);
        props.onSelectFile(acceptedFile);
        setOpen(false);
    };

    if (url || props.url) {
        return (
            <div className="p-4" {...getRootProps({ style })}>
                <div className="text-center w-100 d-flex justify-content-center align-items-center position-relative">
                    <p className="mb-0 px-3">
                        <img style={previewImage} src={url || props.url} alt="Preview" />
                    </p>
                    <div className="fas fa-times h3 active-text position-absolute" style={cancelButtonStyle} onClick={onCancel}></div>
                </div>
            </div>
        );
    } else {
        return (
            <>
                {(!acceptedFile || props.no_preview) && (
                    <div className="file-drop-container p-4" {...getRootProps({ style })}>
                        <input {...getInputProps()} style={{ opacity: 0, position: "absolute", top: "-100%" }} />
                        <div className="text-center w-100">
                            <div className="fas fa-cloud-upload-alt h3 active-text"></div>
                            <p>
                                Drag n drop or <span className="active-text">browse</span> files to set as {props.upload_type ? props.upload_type : "cover photo"}
                            </p>
                        </div>
                    </div>
                )}
                {acceptedFile && !props.no_preview && (
                    <div className="p-4" {...getRootProps({ style })}>
                        <div className="text-center w-100 d-flex justify-content-center align-items-center position-relative">
                            <p className="mb-0 px-3">
                                <img style={previewImage} src={URL.createObjectURL(acceptedFile)} alt="Preview" />
                            </p>
                            <div className="fas fa-times h3 active-text position-absolute" style={cancelButtonStyle} onClick={onCancel}></div>
                        </div>
                    </div>
                )}
                {!props.disable_crop && (
                    <CropImage
                        isOpen={open}
                        cropSize={props.cropSize}
                        aspectRatio={props.aspect_ratio}
                        file={tempAcceptedFile!}
                        onSubmitCropImage={onSubmitCropImage}
                        handleClose={handleClose}
                    />
                )}
            </>
        );
    }
};

export default FileDrop;
