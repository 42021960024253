import { FC, useEffect, useState } from "react";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router";
import { adminEditCause, AdminPaths } from "../../Common/AppRoutes";
import Splash from "../Splash";

const Causes: FC = props => {
    const navigate = useNavigate();
    const [causes, setCauses] = useState<any>(null);
    const [pageCount, setPageCount] = useState(0);
    const [currentCauses, setCurrentCauses] = useState<any>(null);
    const [causeOffset, setCauseOffset] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [causesPerPage, setCausesPerPage] = useState(10);

    useEffect(() => {
        const getCauses = () => {
            HttpService.get("/v1/causes").then(response => {
                if (response.causes) {
                    setCauses(response.causes);
                } else {
                    ToastService.error("Causes not found!");
                }
            });
        };
        if (!causes) {
            getCauses();
        }
    }, [causes]);

    useEffect(() => {
        if (causes && causes.length) {
            const endOffset = causeOffset + causesPerPage;
            setCurrentCauses(causes ? causes.slice(causeOffset, endOffset) : []);
            setPageCount(Math.ceil(causes ? causes.length / causesPerPage : 1));
        } else {
            setCurrentCauses(null);
        }
    }, [causes, causeOffset, causesPerPage]);

    const handlePageClick = (event: React.ChangeEvent<unknown>, value: number) => {
        const newOffset = ((value - 1) * causesPerPage) % causes.length;
        setCauseOffset(newOffset);
    };

    const handleAddCause = () => {
        navigate(AdminPaths.addCause);
    };

    const handleEditCause = (cause: any) => {
        navigate(adminEditCause(cause.id));
    };

    if (!causes) {
        return <Splash />;
    }
    return (
        <div className="container pb-4 container-padding-bottom">
            <div className="text-center h2 mb-1 mt-4">Causes</div>
            <div className="h2 mb-1 mt-4">
                <button type="button" className="btn btn-primary btn-sm" onClick={handleAddCause}>
                    Add Cause
                </button>
            </div>
            <div className="text-center h3 mb-4 mt-2">
                {!currentCauses && (
                    <div className="d-flex flex-column align-items-center fw-bold fs-6">
                        <div className="text-center">No causes found.</div>
                    </div>
                )}
                {currentCauses && currentCauses.length && (
                    <div className="pt-4">
                        <table className="table table-striped table-hover  align-middle fs-6">
                            <thead>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Suggested</th>
                                <th>Created at</th>
                            </thead>
                            <tbody>
                                {currentCauses.map((cause: any, i: any) => (
                                    <tr key={i}>
                                        <td>{cause.id}</td>
                                        <td>{cause.name}</td>
                                        <td>{cause.description}</td>
                                        <td>{cause.is_suggested === true ? "Yes" : "No"}</td>
                                        <td>{Moment(cause.createdAt).format("MMM DD, YYYY HH:MM")}</td>
                                        <td>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => handleEditCause(cause)}>
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Stack spacing={2} className="text-center mb-2">
                            <Pagination count={pageCount} onChange={handlePageClick} />
                        </Stack>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Causes;
