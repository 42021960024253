import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "../Common/Constants";
import { EventType } from "../Enums/EventType";

export class EventService {
    public static trackEvent(event: EventType, params: any) {
        if (MIXPANEL_TOKEN) {
            mixpanel.track(event, params);
        }
    }

    public static trackUser(email: string) {
        if (MIXPANEL_TOKEN) {
            mixpanel.identify(email);
        }
    }
}
