import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { IGuest } from "../Interfaces/IGuest";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { GuardedRoutes } from "./AppRoutes";
import { isGuestUser } from "./GuardedRoute";

export const capitalizeFirstLetter = (value: string): string => {
    return value ? value.charAt(0).toUpperCase() + value.substring(1).toLowerCase() : "";
};

export const maskEmail = (value: string = "") => {
    const [name, domain] = value.split("@");
    const len = name.length;
    const maskedName = name[0] + "*".repeat(len - 2) + name[len - 1];
    const maskedEmail = maskedName + "@" + domain;
    return maskedEmail;
};

export const maskPhoneNumber = (value: string = "") => {
    const len = value.length;
    return value[0] + value[1] + value[2] + "*".repeat(len - 4) + value[len - 1];
};

const getDifference = (key: string, array1: any, array2: any) => {
    return array1.filter((object1: any) => {
        return !array2.some((object2: any) => {
            return object1[key] === object2[key];
        });
    });
};

export const getArrayOfObjectsDifference = (key: string, array1: any, array2: any) => {
    return [...getDifference(key, array1, array2), ...getDifference(key, array2, array1)];
};

export const isChrome = () => {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

export const isUUID = (value: string) => {
    return (value + "").indexOf("-") >= 0;
};

export const GenerateUniqueNumberWithoutUUID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const LogValues = (positionMessage: string, additionalParams: any = {}) => {
    if (process.env.REACT_APP_ENVIRONMENT === "test") {
        console.log("Start ---------------------------------------------------");
        console.log("Message: " + positionMessage);
        console.log("Additional params", JSON.stringify(additionalParams));
        console.log("userData", localStorage.getItem("userData"));
        console.log("redirectUrl", localStorage.getItem("redirectUrl"));
        console.log("slug", localStorage.getItem("slug"));
        console.log("End ---------------------------------------------------");
    }
};

export const removeHostAndParamsFromURL = (url: string) => {
    try {
        if (url) {
            return new URL(url).pathname;
        }
    } catch (error) {
        return url;
    }
};

export const isJsonString = (value: string = "") => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
};

export const isValidatePhoneNumber = (phoneNumber: string = "") => {
    if (phoneNumber) {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
        const callingCodeFromEnteredPhoneNumber = phoneNumber.substring(1);
        if (!parsedPhoneNumber || callingCodeFromEnteredPhoneNumber === parsedPhoneNumber?.countryCallingCode) {
            return true;
        }
        return isPossiblePhoneNumber(phoneNumber);
    }
    return true;
};

export const shouldTriggerPopupForGuardedRoutes = () => {
    const redirectUrl = localStorage.getItem("redirectUrl") || "";
    if (redirectUrl && redirectUrl !== "undefined") {
        const redirectPage = redirectUrl.split("/")[1];
        const guardedRoutes = GuardedRoutes.map((route: any) => route.path);
        const path = (guardedRoutes || []).find(guardedRoute => guardedRoute.startsWith("/" + redirectPage));
        if (path) {
            return true;
        }
    }
};

export const isUserPresentInGuestList = (guestList: IGuest[], user: IUserInfo) => {
    let isPresentInGuestList = false;
    for (let guest of guestList) {
        if (guest.email === user.email) {
            isPresentInGuestList = true;
        }
    }
    return isPresentInGuestList;
};

export const getVidInviteEmailInfo = () => {
    const url = localStorage.getItem("redirectUrl") ? localStorage.getItem("redirectUrl") : window.location.pathname + window.location.search;
    if (url && url?.includes("vidinvite_email_data")) {
        const queryParams = new URLSearchParams(window.location.origin + url);
        const vidInviteEmailData = queryParams.get("vidinvite_email_data") || "";
        const vidInviteEmailDataObject = JSON.parse(atob(vidInviteEmailData) || "{}");
        return vidInviteEmailDataObject;
    }
    return null;
};

export const getUserEmail = (authContextUser: IUserInfo | undefined, windowLocationSearchParams: string) => {
    let userEmail = authContextUser?.email;
    if (isGuestUser(authContextUser) && windowLocationSearchParams.includes("guest_info")) {
        const queryParams = new URLSearchParams(windowLocationSearchParams);
        let guestInfo = JSON.parse(atob((queryParams.get("guest_info") || "").replace(" ", "+")));
        userEmail = guestInfo?.guest_email;
    }
    return userEmail;
};

export const isEmailAddress = (value: string = "") => {
    const pattern = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    return value.match(pattern);
};
