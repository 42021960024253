import React, { FC, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Splash from "../Components/Splash";
import { GuardedRoute } from "./GuardedRoute";
import { WrappedAuthProvider } from "../Contexts/AuthProvider";
import Progress from "./Progress";
import AddCompany from "../Components/Admin/AddCompany";
import Causes from "../Components/Admin/Causes";
import AddCause from "../Components/Admin/AddCause";
import EditCause from "../Components/Admin/EditCause";
import GivingjoyGiftRedemption from "../Components/Gifts/GivingjoyGiftRedemption";

const App = React.lazy(() => import("../App"));
const Landing = React.lazy(() => import("../Components/Landing"));
const VidInviteReady = React.lazy(() => import("../Components/Creator/VidInviteReady"));
const CreateVidInvite = React.lazy(() => import("../Components/Creator/CreateVidInvite"));
const Dashboard = React.lazy(() => import("../Components/Dashboard/Dashboard"));
const Contribute = React.lazy(() => import("../Components/VidInvite/Contribute"));
const Celebration = React.lazy(() => import("../Components/VidInvite/Celebration"));
const MyAccount = React.lazy(() => import("../Components/Profile/MyAccount"));
const EditProfile = React.lazy(() => import("../Components/Profile/EditProfile"));
const Thankyou = React.lazy(() => import("../Components/VidInvite/Thankyou"));
const ThankyouSent = React.lazy(() => import("../Components/VidInvite/ThankyouSent"));
const ThankyouView = React.lazy(() => import("../Components/VidInvite/ThankyouView"));
const Main = React.lazy(() => import("../Components/Admin/Main"));
const Companies = React.lazy(() => import("../Components/Admin/Companies"));
const AllGifts = React.lazy(() => import("../Components/Admin/AllGifts"));
const VidInvites = React.lazy(() => import("../Components/Admin/VidInvites"));
const EditVidInvite = React.lazy(() => import("../Components/Admin/EditVidInvite"));
const EditCompany = React.lazy(() => import("../Components/Admin/EditCompany"));
const Posts = React.lazy(() => import("../Components/Admin/Posts"));
const Gifts = React.lazy(() => import("../Components/Admin/Gifts"));

const WhitelistedEmails = React.lazy(() => import("../Components/Admin/WhitelistedEmails"));
const EmailOtpAccess = React.lazy(() => import("../Components/Login/EmailOtpAccess"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const Terms = React.lazy(() => import("./Terms"));

export const Paths = {
    landing: "/",
    login: "/login",
    createVidInvite: "/vidinvite",
    vidinviteReady: "/invite/:eventId",
    contribute: "/contribute/:eventId",
    celebration: "/celebration/:eventId",
    privacyPolicy: "/privacy-policy",
    terms: "/terms",
    dashboard: "/dashboard",
    myAccount: "/account",
    editProfile: "/edit-profile",
    sendThankYou: "/thank-you/:eventId",
    thankYouSent: "/thank-you-sent/:eventId/thankyou/:thankyouVideoId",
    thankYouView: "/thank-you-view/:thankyouVideoId",
    givingjoyGiftRedemption: "/givingjoy-gift-redeem/:giftId",
    emailOtpAccess: "/email-access",
    processing: "/processing"
};

export const AdminPaths = {
    main: "/admin",
    companies: "/admin/companies",
    allgifts: "/admin/gifts",
    addCompany: "/admin/company",
    editCompany: "/admin/company/:companyId",
    vidinvites: "/admin/vidinvites/:companyId",
    causes: "/admin/causes",
    addCause: "/admin/cause",
    editCause: "/admin/cause/:causeId",
    editVidInvite: "/admin/vidinvite/:eventId",
    posts: "/admin/vidinvite/:eventId/posts",
    gifts: "/admin/vidinvite/:eventId/gifts",
    whitelistedEmails: "/admin/whitelisted-emails"
};

export const vidInviteReadyPath = (eventId: string): string => {
    return Paths.vidinviteReady.replace(":eventId", `${eventId}`);
};

export const contributePath = (eventId: string): string => {
    return Paths.contribute.replace(":eventId", `${eventId}`);
};

export const celebrationPath = (eventId: string): string => {
    return Paths.celebration.replace(":eventId", `${eventId}`);
};

export const thankyouPath = (eventId: string): string => {
    return Paths.sendThankYou.replace(":eventId", `${eventId}`);
};

export const thankyouSent = (eventId: string, thankyouVideoId: number): string => {
    return Paths.thankYouSent.replace(":eventId", `${eventId}`).replace(":thankyouVideoId", `${thankyouVideoId}`);
};

export const thankyouView = (eventId: string, thankyouVideoId: number): string => {
    return Paths.thankYouView.replace(":eventId", `${eventId}`).replace(":thankyouVideoId", `${thankyouVideoId}`);
};

export const givingjoyGiftRedemptionPath = (giftId: string): string => {
    return Paths.givingjoyGiftRedemption.replace(":giftId", `${giftId}`);
};

export const adminEditVidInvite = (eventId: string): string => {
    return AdminPaths.editVidInvite.replace(":eventId", `${eventId}`);
};

export const adminViewVidinvites = (companyId: string): string => {
    return AdminPaths.vidinvites.replace(":companyId", `${companyId}`);
};

export const adminEditCompany = (companyId: string): string => {
    return AdminPaths.editCompany.replace(":companyId", `${companyId}`);
};

export const adminViewPosts = (eventId: string): string => {
    return AdminPaths.posts.replace(":eventId", `${eventId}`);
};

export const adminViewGifts = (eventId: string): string => {
    return AdminPaths.gifts.replace(":eventId", `${eventId}`);
};

export const emailOtpAccess = (emailAccessParams: string): string => {
    return `${Paths.emailOtpAccess}?params=${emailAccessParams}`;
};

export const adminEditCause = (causeId: string): string => {
    return AdminPaths.editCause.replace(":causeId", `${causeId}`);
};

export const urlForPath = (path: string): string => {
    return `${window.location.origin}/${path.replace(/^\/|\/$/g, "")}`;
};

export const GuardedRoutes = [
    { path: Paths.dashboard, component: <Dashboard /> },
    { path: Paths.createVidInvite, component: <CreateVidInvite /> },
    { path: Paths.vidinviteReady, component: <VidInviteReady /> },
    { path: Paths.myAccount, component: <MyAccount /> },
    { path: Paths.editProfile, component: <EditProfile /> },
    { path: Paths.sendThankYou, component: <Thankyou /> },
    { path: Paths.thankYouSent, component: <ThankyouSent /> },
    { path: Paths.thankYouView, component: <ThankyouView /> },
    { path: Paths.givingjoyGiftRedemption, component: <GivingjoyGiftRedemption /> },
    { path: AdminPaths.main, component: <Main /> },
    { path: AdminPaths.companies, component: <Companies /> },
    { path: AdminPaths.causes, component: <Causes /> },
    { path: AdminPaths.addCause, component: <AddCause /> },
    { path: AdminPaths.editCause, component: <EditCause /> },
    { path: AdminPaths.allgifts, component: <AllGifts /> },
    { path: AdminPaths.addCompany, component: <AddCompany /> },
    { path: AdminPaths.vidinvites, component: <VidInvites /> },
    { path: AdminPaths.editVidInvite, component: <EditVidInvite /> },
    { path: AdminPaths.editCompany, component: <EditCompany /> },
    { path: AdminPaths.posts, component: <Posts /> },
    { path: AdminPaths.gifts, component: <Gifts /> },
    { path: AdminPaths.whitelistedEmails, component: <WhitelistedEmails /> }
];

const AppRoutes: FC = () => (
    <Suspense fallback={<Splash />}>
        <BrowserRouter>
            <ScrollToTop />
            <WrappedAuthProvider>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path={Paths.landing} element={<Landing />} />
                        <Route path={Paths.processing} element={<Progress />} />
                        {GuardedRoutes.map((guardedRoute: any, i: any) => (
                            <Route key={i} path={guardedRoute.path} element={<GuardedRoute>{guardedRoute.component}</GuardedRoute>} />
                        ))}
                        <Route path={Paths.celebration} element={<Celebration />} />
                        <Route path={Paths.contribute} element={<Contribute />} />
                        <Route path={Paths.emailOtpAccess} element={<EmailOtpAccess />} />
                        <Route path={Paths.terms} element={<Terms />} />
                        <Route path={Paths.privacyPolicy} element={<PrivacyPolicy />} />
                    </Route>
                </Routes>
            </WrappedAuthProvider>
        </BrowserRouter>
    </Suspense>
);

export default AppRoutes;
