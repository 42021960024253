import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { API_KEY, BACKEND_URL, ENCRYPTION_KEY } from "../Common/Constants";
import * as crypto from "crypto-js";

const EXCLUDE_ROUTES = [
    "/v1/convert/video",
    "/v1/upload/thumbnail",
    "/v1/video/signeddata",
    "/v1/upload/banner",
    "/v1/upload/picture",
    "/v1/upload/user/picture",
    "/v1/company/upload/images",
    "/v1/audio/signeddata",
    "/v1/cause/upload/images",
    "/v1/video"
];
export const PLUS = "+";
export const REPLACE_VALUE = "xMl3Jk";
export const REGEX = /xMl3Jk/g;
export const PLUS_REGEX = "\\+";
export const GLOBAL_FLAG_REGEX = "g";

export default class HttpService {
    static handleResponse(url: string, data: any, isAxiosError = false): any {
        const decryptedResponse: any = this.decryptResponse(url, data);
        if (isAxiosError) {
            const errorMessage = decryptedResponse.data ? decryptedResponse.data.message : "";
            throw new Error(
                JSON.stringify({
                    status: decryptedResponse.status,
                    message: errorMessage
                })
            );
        } else {
            return decryptedResponse.data;
        }
    }

    static async get(path: string) {
        try {
            let headers;
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                headers = {
                    "x-access-token": userData.token
                };
            }
            const request = this.encryptRequest("get", path, headers);
            const response = await axios(request);
            return this.handleResponse(path, response);
        } catch (error: any) {
            return this.handleResponse(path, error.response, true);
        }
    }

    static async getWithAPIKey(path: string) {
        try {
            let headers = {
                authorization: `Basic ${API_KEY}`
            };
            const request = this.encryptRequest("get", path, headers);
            const response = await axios(request);
            return this.handleResponse(path, response);
        } catch (error: any) {
            return this.handleResponse(path, error.response, true);
        }
    }

    static async postFormData(path: string, formData: any, isMultipartFormRequest?: boolean, isAPiKey = false) {
        try {
            let headers: any;
            if (isMultipartFormRequest) {
                headers = {
                    "content-type": "multipart/form-data"
                };
            }
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "")!;
                headers = {
                    "content-type": isMultipartFormRequest ? "multipart/form-data" : "application/json",
                    "x-access-token": userData.token
                };
            }
            if (isAPiKey) {
                headers = {
                    authorization: `Basic ${API_KEY}`
                };
            }
            const request = this.encryptRequest("post", path, headers, formData);
            const response = await axios(request);
            return this.handleResponse(path, response);
        } catch (error: any) {
            return this.handleResponse(path, error.response, true);
        }
    }

    static async putFormData(path: string, formData: any, isAPiKey = false) {
        try {
            let headers: any;
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "")!;
                headers = {
                    "content-type": "application/json",
                    "x-access-token": userData.token
                };
            }
            if (isAPiKey) {
                headers = {
                    authorization: `Basic ${API_KEY}`
                };
            }
            const request = this.encryptRequest("put", path, headers, formData);
            const response = await axios(request);
            return this.handleResponse(path, response);
        } catch (error: any) {
            return this.handleResponse(path, error.response, true);
        }
    }

    static async delete(path: string) {
        try {
            let headers;
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                headers = {
                    "x-access-token": userData.token
                };
            }
            const request = this.encryptRequest("delete", path, headers);
            const response = await axios(request);
            return this.handleResponse(path, response);
        } catch (error: any) {
            return this.handleResponse(path, error.response, true);
        }
    }

    static encryptRequest(method: Method, url: string, authHeaders: any, body?: any): AxiosRequestConfig {
        const request: AxiosRequestConfig = {
            method,
            url: BACKEND_URL + url,
            headers: {
                "Content-Type": "text/plain",
                Accept: "text/plain"
            }
        };
        if (localStorage.getItem("slug")) {
            if (authHeaders) {
                authHeaders["x-company"] = localStorage.getItem("slug");
            } else {
                authHeaders = {
                    "x-company": localStorage.getItem("slug")
                };
            }
        }
        let encryptedHeaders;
        if (authHeaders) {
            encryptedHeaders = crypto.AES.encrypt(JSON.stringify(authHeaders), ENCRYPTION_KEY!).toString().replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
        }
        if (ENCRYPTION_KEY && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && !(body instanceof FormData)) {
            if (body) {
                const encryptedBody = crypto.AES.encrypt(JSON.stringify(body), ENCRYPTION_KEY).toString().replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
                request.data = encryptedBody;
            }
            if (encryptedHeaders) {
                request.headers = {
                    "x-headers": encryptedHeaders,
                    ...request.headers
                };
            }
        } else if (ENCRYPTION_KEY && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && body instanceof FormData) {
            if (encryptedHeaders) {
                request.headers = {
                    "x-headers": encryptedHeaders,
                    ...request.headers
                };
            }
            request.data = body;
        } else {
            request.headers = {
                ...authHeaders
            };
            request.data = body;
        }
        return request;
    }

    static decryptResponse(url: string, response: AxiosResponse): AxiosResponse {
        const decryptedResponse: AxiosResponse = {
            ...response,
            data: response
        };
        if (response.data !== "" && typeof response.data !== "object" && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && ENCRYPTION_KEY) {
            let decryptedBody = crypto.AES.decrypt((response.data as string).replace(REGEX, PLUS), ENCRYPTION_KEY).toString(crypto.enc.Utf8);
            decryptedBody = JSON.parse(decryptedBody);
            decryptedResponse.data = decryptedBody;
        }
        return decryptedResponse;
    }
}
