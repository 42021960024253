import { AccountType } from "../Enums/AccountType";
import { Role } from "../Enums/Role";
import { IUserInfo } from "../Interfaces/IUserInfo";

export function SocialLoginUserInfoMap(
    email: string,
    userId: number,
    companyId: number,
    name: string,
    accountType: AccountType,
    token: string,
    role: Role,
    phoneNumber: string
): IUserInfo {
    return {
        email,
        name,
        userId,
        companyId,
        account_type: accountType,
        token,
        role,
        phoneNumber
    };
}
