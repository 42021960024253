import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import reportWebVitals from "./reportWebVitals";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import AppRoutes from "./Common/AppRoutes";
import ReactGA from "react-ga4";
import "./index.scss";
import { MIXPANEL_TOKEN, LOGROCKET_PROJECT } from "./Common/Constants";

ReactGA.initialize("G-6VKX53BW4J");
ReactGA.send("pageview");

if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN || "");
}

if (LOGROCKET_PROJECT) {
    LogRocket.init(LOGROCKET_PROJECT || "");
    setupLogRocketReact(LogRocket);
}

ReactDOM.hydrate(
    <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
        scriptProps={{
            async: true,
            defer: true
        }}
    >
        <React.StrictMode>
            <AppRoutes />
        </React.StrictMode>
    </GoogleReCaptchaProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
