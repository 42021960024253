import { Box } from "@mui/system";
import jwt from "jwt-decode";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useContext, Fragment, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { AuthContext } from "../../Contexts/AuthContext";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Error from "../../Common/Error";
import { SocialLoginUserInfoMap } from "../../Util/UserInfoMap";
import { Desktop, Mobile } from "../../Common/Responsive";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { maskEmail, maskPhoneNumber, isJsonString } from "../../Common/Helper";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { PhoneMessageOption } from "../../Enums/PhoneMessageOption";
import { EventService } from "../../Services/EventService";

export const inputStyles = {
    cursor: "pointer",
    border: 0,
    width: "50px",
    height: "50px",
    fontSize: "24px",
    borderRadius: 0,
    borderBottom: "3px solid #cbcbcb"
};

const OtpLogin = props => {
    const authContext = useContext(AuthContext);
    const [OTP, setOTP] = useState("");
    const [isEmailEnterView, setIsEmailEnterView] = useState(true);
    const [phoneMessageOption, setPhoneMessageOption] = useState(PhoneMessageOption.PHONE);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otpFormData, setOtpFormData] = useState();
    const [error, setError] = useState(false);
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const { register, getValues, formState, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            email: ""
        },
        mode: "onChange"
    });

    const sendOtpClicked = async data => {
        setProcessing(true);
        setOtpFormData(data);
        const response = await generateOTP(data);
        if (response && response.status === 200) {
            setIsEmailEnterView(false);
        }
    };

    const cancelClicked = () => {
        props.onCancel();
    };

    const handlePhoneOptionChange = event => {
        setPhoneMessageOption(event.target.value);
    };

    const proceedClicked = async () => {
        if (!OTP) {
            setError(true);
            return;
        }
        try {
            const response = await HttpService.postFormData("/v1/otp/verify", {
                email: otpFormData.email,
                company_id: authContext.company.id,
                code: OTP
            });
            if (response.status !== 200) {
                ToastService.error(response.message);
            } else if (!response.is_verified) {
                ToastService.error("Invalid code");
            } else {
                const token = response.user_data.token;
                let decodedToken;
                if (token) {
                    decodedToken = jwt(token);
                }
                const userData = SocialLoginUserInfoMap(
                    decodedToken.email,
                    decodedToken.userId,
                    decodedToken.companyId,
                    decodedToken.name,
                    decodedToken.accountType,
                    token,
                    decodedToken.role,
                    decodedToken.phoneNumber
                );
                if (authContext.isLoggedInAdminUserSameAsLinkedToCompany(userData)) {
                    authContext.setUserData(userData);
                    EventService.trackUser(userData.email);
                    localStorage.setItem("userData", JSON.stringify(userData));
                    navigate(localStorage.getItem("redirectUrl") || "/", { replace: true });
                    localStorage.removeItem("redirectUrl");
                    props.handleClose();
                } else {
                    props.handleClose(false);
                }
            }
        } catch (error) {
            const errorObject = isJsonString(error.message) ? JSON.parse(error.message) : null;
            ToastService.error(errorObject.message ? errorObject.message : "Something went wrong verifying code");
        }
    };

    const resendOtpClicked = async () => {
        const response = await generateOTP(otpFormData);
        if (response && response.status === 200) {
            ToastService.success("Code has been resent. Please check your email");
        }
    };

    const generateOTP = async data => {
        try {
            const mediums = ["email"];
            if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
                mediums.push(phoneMessageOption);
            }
            const response = await HttpService.postFormData("/v1/otp/create", {
                company_id: authContext.company.id,
                name: data.name,
                email: data.email,
                phone_number: phoneNumber,
                mediums
            });
            if (response.status !== 200) {
                setProcessing(false);
                ToastService.error(response.message);
            } else {
                setProcessing(false);
                return response;
            }
        } catch (error) {
            setProcessing(false);
            const errorObject = isJsonString(error.message) ? JSON.parse(error.message) : null;
            ToastService.error(errorObject ? errorObject.message : "Something went wrong sending code");
        }
    };

    return (
        <div className="login-container">
            {isEmailEnterView && (
                <div>
                    We will send a <Box sx={{ fontWeight: "bold", display: "inline" }}>4-digit code</Box> to your email:
                    <form onSubmit={handleSubmit(sendOtpClicked)}>
                        <div>
                            <input
                                className={"w-100 otp-input mb-2 mt-2 " + (formState.errors.email && formState.touchedFields.email ? "error" : "")}
                                placeholder="Enter your email"
                                style={{ ...inputStyles, fontSize: "16px" }}
                                {...register("email", {
                                    required: true,
                                    pattern:
                                        /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                                    onChange: event => (event.target.value = event.target.value.trim().toLowerCase())
                                })}
                            />
                            {formState.errors.email?.type === "required" && formState.touchedFields.email && <Error>Your email is required</Error>}
                            {formState.errors.email?.type === "pattern" && formState.touchedFields.email && <Error>Please enter a valid email</Error>}
                        </div>
                        <div>
                            <input
                                className={"w-100 otp-input mb-2 mt-2 " + (formState.errors.name && formState.touchedFields.name ? "error" : "")}
                                placeholder="Enter your name"
                                style={{ ...inputStyles, fontSize: "16px" }}
                                {...register("name", {
                                    required: true
                                })}
                            />
                            {formState.errors.name?.type === "required" && formState.touchedFields.name && <Error>Your name is required</Error>}
                        </div>
                        <div>
                            <PhoneInput
                                className={"w-100 otp-input mb-2 mt-2 " + (phoneNumber && !isPossiblePhoneNumber(phoneNumber) ? "error" : "")}
                                placeholder="Phone (optional)"
                                style={{ ...inputStyles, fontSize: "16px" }}
                                defaultCountry="US"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                            {phoneNumber && !isPossiblePhoneNumber(phoneNumber) && <Error>Invalid phone number</Error>}
                        </div>
                        <div>
                            <RadioGroup
                                aria-label="Message options"
                                className="d-flex flex-row ml-2 mb-2 mt-2"
                                defaultValue={PhoneMessageOption.PHONE}
                                onChange={handlePhoneOptionChange}
                            >
                                <FormControlLabel className="message-option-label" value={PhoneMessageOption.PHONE} control={<Radio color="primary" />} label="Phone" />
                                <FormControlLabel value={PhoneMessageOption.WHATSAPP} control={<Radio color="primary" />} label="WhatsApp" />
                            </RadioGroup>
                        </div>
                        <div className="d-flex justify-content-evenly pb-4">
                            <button type="button" className="w-100 btn btn-outline-primary m-2" onClick={cancelClicked}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="w-100 btn btn-primary m-2"
                                disabled={!formState.isValid || processing || (phoneNumber && !isPossiblePhoneNumber(phoneNumber))}
                            >
                                {processing && (
                                    <span className="mr-2 ml-1">
                                        <i className="fas fa-spinner fa-pulse"></i>
                                    </span>
                                )}
                                Send Code
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {!isEmailEnterView && (
                <>
                    <div className="pb-1 pt-3 text-center">Please enter the 4 digit code sent to:</div>
                    <div className="pt-1 text-center">
                        via email: &nbsp;<Box sx={{ fontWeight: "bold", display: "inline" }}>{maskEmail(getValues("email"))}</Box>
                    </div>
                    {phoneNumber && (
                        <div className="pt-1 text-center">
                            via {phoneMessageOption === PhoneMessageOption.PHONE ? "TXT" : "WhatsApp"}: &nbsp;
                            <Box sx={{ fontWeight: "bold", display: "inline" }}>{maskPhoneNumber(phoneNumber)}</Box>
                        </div>
                    )}
                    <div className="d-flex justify-content-center otp">
                        <Desktop>
                            <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                inputClassName="otp-input"
                                inputStyles={inputStyles}
                            />
                        </Desktop>
                        <Mobile>
                            <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                inputClassName="otp-input"
                                inputStyles={{ ...inputStyles, width: "32px", fontSize: "21px" }}
                            />
                        </Mobile>
                    </div>
                    {error && !OTP && (
                        <div className="d-flex justify-content-center pt-2">
                            <Error>Please enter code</Error>
                        </div>
                    )}
                    <div className="d-flex justify-content-evenly pt-5 pb-4">
                        <ResendOTP
                            renderTime={() => Fragment}
                            renderButton={props => {
                                return (
                                    <div>
                                        <span className="text-muted">Didn't receive a code? </span>
                                        <button className="btn btn-outline-primary" {...props} onClick={resendOtpClicked}>
                                            {props.remainingTime !== 0 ? `Resend code in ${props.remainingTime} sec` : "Resend Code"}
                                        </button>
                                    </div>
                                );
                            }}
                            maxTime="30"
                        />
                    </div>
                    <div className="d-flex justify-content-evenly pb-4">
                        <button className="w-100 btn btn-outline-primary m-2" onClick={cancelClicked}>
                            Cancel
                        </button>
                        <button className="w-100 btn btn-primary m-2" onClick={proceedClicked}>
                            Verify & Proceed
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default OtpLogin;
