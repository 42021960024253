import { createContext } from "react";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { AuthData } from "./AuthProvider";

const defaultState: AuthData = {
    signIn: () => {},
    signOut: () => {},
    setUserData: (userData: IUserInfo) => {},
    isLoggedInAdminUserSameAsLinkedToCompany: (userData: any) => {},
    guestLogin: async () => {},
    guestLogout: () => {},
    user: undefined,
    whitelistedEmails: undefined,
    vidinviteInfo: undefined,
    company: undefined,
    redirectPage: undefined
};

export const AuthContext = createContext<AuthData>(defaultState);
