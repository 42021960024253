export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", error => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(imageSrc: string, pixelCrop: any, rotation = 0, flip = { horizontal: false, vertical: false }): Promise<File> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return new Promise((resolve, reject) => {
            reject("Canvas not supported! Please use a modern browser");
        });
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob: any) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = imageSrc;
                const fileObject: File = new File([blob], imageSrc, {
                    type: "image/jpeg"
                });
                resolve(fileObject);
            },
            "image/jpeg",
            1
        );
    });
}

export async function getRotatedImage(imageSrc: string, rotation = 0): Promise<File> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
    if (orientationChanged) {
        canvas.width = image.height;
        canvas.height = image.width;
    } else {
        canvas.width = image.width;
        canvas.height = image.height;
    }

    if (ctx) {
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
    }

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob: any) => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = imageSrc;
                const fileObject: File = new File([blob], imageSrc, {
                    type: "image/jpeg"
                });
                resolve(fileObject);
            },
            "image/jpeg",
            1
        );
    });
}

export async function resizeImage(callback: (arg0: string) => void, base64image: string, width: number = 1080, height: number = 1080) {
    let img = new Image();
    img.src = base64image;
    img.onload = () => {
        // Check if the image require resize at all
        if (img.height <= height && img.width <= width) {
            callback(base64image);
        } else {
            // Make sure the width and height preserve the original aspect ratio and adjust if needed
            if (img.height > img.width) {
                width = Math.floor(height * (img.width / img.height));
            } else {
                height = Math.floor(width * (img.height / img.width));
            }

            let resizingCanvas: HTMLCanvasElement = document.createElement("canvas");
            let resizingCanvasContext = resizingCanvas.getContext("2d") as CanvasRenderingContext2D;

            // Start with original image size
            resizingCanvas.width = img.width;
            resizingCanvas.height = img.height;

            // Draw the original image on the (temp) resizing canvas
            resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

            let curImageDimensions = {
                width: Math.floor(img.width),
                height: Math.floor(img.height)
            };

            let halfImageDimensions = {
                width: 0,
                height: 0
            };

            // Quickly reduce the dize by 50% each time in few iterations until the size is less then
            // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
            // created with direct reduction of very big image to small image
            while (curImageDimensions.width * 0.5 > width) {
                // Reduce the resizing canvas by half and refresh the image
                halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
                halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

                resizingCanvasContext.drawImage(
                    resizingCanvas,
                    0,
                    0,
                    curImageDimensions.width,
                    curImageDimensions.height,
                    0,
                    0,
                    halfImageDimensions.width,
                    halfImageDimensions.height
                );

                curImageDimensions.width = halfImageDimensions.width;
                curImageDimensions.height = halfImageDimensions.height;
            }

            // Now do final resize for the resizingCanvas to meet the dimension requirments
            // directly to the output canvas, that will output the final image
            let outputCanvas: HTMLCanvasElement = document.createElement("canvas");
            let outputCanvasContext = outputCanvas.getContext("2d") as CanvasRenderingContext2D;

            outputCanvas.width = width;
            outputCanvas.height = height;

            outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height, 0, 0, width, height);

            // output the canvas pixels as an image. params: format, quality
            callback(outputCanvas.toDataURL("image/jpeg", 0.85));
        }
    };
}

export function dataURLtoFile(dataurl: string, filename: string): File {
    let arr = dataurl.split(",") as any;
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
