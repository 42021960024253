import { FC, useContext, useEffect, useState } from "react";
import { Link } from "@mui/material";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import { useLocation, useNavigate, useParams } from "react-router";
import { inputStyles } from "../Login/OtpLogin";
import { AuthContext } from "../../Contexts/AuthContext";
import { GiftStatus } from "../../Enums/GiftStatus";
import { celebrationPath } from "../../Common/AppRoutes";
import Splash from "../Splash";

const GivingjoyGiftRedemption: FC = props => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const [vidinvite, setVidinvite] = useState<any>(null);
    const [individualAmountTouched, setIndividualAmountTouched] = useState(false);
    const [individualAmount, setIndividualAmount] = useState();
    const [calculatedMatchAmount, setCalculatedMatchAmount] = useState<any>();
    const [selectedCause, setSelectedCause] = useState<any>(null);
    const [totalAmount, setTotalAmount] = useState<any>(null);
    const [access, setAccess] = useState<boolean>(true);
    const { giftId } = useParams();
    const [gift, setGift] = useState<any>(null);

    useEffect(() => {
        const getVidinvite = () => {
            let vidinviteId;
            if (location.search.includes("guest_info")) {
                const queryParams = new URLSearchParams(location.search);
                vidinviteId = JSON.parse(atob((queryParams.get("guest_info") || "").replace(" ", "+"))).vidinvite_id;
            } else if (sessionStorage.getItem("guest_info")) {
                vidinviteId = JSON.parse(sessionStorage.getItem("guest_info") || "").vidinvite_id;
            }
            if (vidinviteId) {
                HttpService.get(`/v1/vidinvite/${vidinviteId}`).then(response => {
                    if (response.vidinvite) {
                        setVidinvite(response.vidinvite);
                        if ((response.vidinvite.selected_causes || []).length === 1) {
                            setSelectedCause(response.vidinvite.selected_causes[0]);
                            response.vidinvite.selected_causes[0].selected = true;
                        }
                        if (response.vidinvite.company_id !== authContext.company.id) {
                            setAccess(false);
                        }
                    } else {
                        ToastService.error("Vidinvite not found!");
                    }
                });
            }
        };
        if (!vidinvite) {
            getVidinvite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vidinvite]);

    useEffect(() => {
        const timer = setTimeout(() => {
            sessionStorage.removeItem("guest_info");
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const getGift = () => {
            if (giftId) {
                HttpService.get(`/v1/gift/${giftId}`).then(response => {
                    if (response.gift) {
                        setGift(response.gift);
                        if (response.gift.status === GiftStatus.REDEEMED) {
                            setAccess(false);
                        } else if (response.gift.email !== authContext?.user?.email) {
                            setAccess(false);
                        }
                    } else {
                        ToastService.error("Gift not found!");
                    }
                });
            }
        };
        if (!gift) {
            getGift();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gift]);

    const causeClicked = (clickedCause: any) => {
        (vidinvite.selected_causes || []).forEach((cause: any) => {
            cause.selected = cause.id === clickedCause.id ? !cause.selected : false;
        });
        setSelectedCause(clickedCause);
    };

    const onChangeAmount = (value: any) => {
        setIndividualAmount(isNaN(value) ? "" : value);
        let maxMatchAmount = 0;
        let calculatedMatchAmountFromPercentage = 0;
        if (vidinvite.matching_charity && vidinvite.matching_charity.max_match_amount) {
            maxMatchAmount = parseInt(vidinvite.matching_charity.max_match_amount);
        }
        if (vidinvite.matching_charity && vidinvite.matching_charity.match_percentage) {
            calculatedMatchAmountFromPercentage = (parseInt(vidinvite.matching_charity.match_percentage) * value) / 100;
        }
        const calculatedMatchAmount = maxMatchAmount < calculatedMatchAmountFromPercentage ? maxMatchAmount : calculatedMatchAmountFromPercentage;
        setCalculatedMatchAmount(calculatedMatchAmount);
        setTotalAmount(gift.amount / 100 + value + calculatedMatchAmount);
    };

    const formIncomplete = () => {
        if (!selectedCause) {
            return true;
        }
        return false;
    };

    const redeemGift = async () => {
        const response = await HttpService.postFormData("/v1/givingjoy/redeem/gift", {
            email: authContext.user?.email,
            gift_id: giftId,
            vidinvite_id: vidinvite.encrypted_id,
            match_amount: calculatedMatchAmount ? calculatedMatchAmount * 100 : 0,
            individual_contribution: individualAmount ? individualAmount * 100 : 0,
            selected_cause: selectedCause
        });
        if (!response.id) {
            ToastService.error(response.message);
            return;
        }
        ToastService.success(response.message);
        navigate(celebrationPath(vidinvite.encrypted_id));
    };

    if (!vidinvite || !gift) {
        return <Splash />;
    }

    return (
        <div className="container pb-4">
            {access === false && vidinvite.company_id !== authContext.company.id && (
                <div className="d-flex flex-column align-items-center fw-bold fs-3 mt-5 pt-5">
                    <div className="text-center text-danger">Pledge card belongs to different company.</div>
                </div>
            )}
            {access === false && gift.status === GiftStatus.REDEEMED && (
                <div className="d-flex flex-column align-items-center fw-bold fs-3 mt-5 pt-5">
                    <div className="text-center text-danger">Pledge card updated.</div>
                </div>
            )}
            {access === false && gift.email !== authContext?.user?.email && (
                <div className="d-flex flex-column align-items-center fw-bold fs-3 mt-5 pt-5">
                    <div className="text-center text-danger">Pledge card belongs to different user.</div>
                </div>
            )}
            {access && (
                <>
                    <div className="text-center h3 m-4">Pledge Card</div>
                    <div className="card mt-3">
                        <div className="row h4 mb-3">
                            <span className="text-center">{vidinvite.selected_causes.length > 1 ? "Select a cause" : "Designated cause"}</span>
                        </div>
                        <div className="d-flex justify-content-evenly flex-wrap">
                            {vidinvite.selected_causes.map((cause: any, i: any) => (
                                <div key={i} className={"p-3 border cause-container m-1" + (cause.selected ? " selected" : "")} onClick={event => causeClicked(cause)}>
                                    {cause.selected && <i className="selected-checkmark fas fa-check-circle"></i>}
                                    <img
                                        src={cause.logo_url || "/images/placeholder.png"}
                                        alt="Cause logo"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "/images/placeholder.png";
                                        }}
                                    ></img>
                                    <div className="mt-4 text-center">
                                        {!!cause.charity_url && (
                                            <Link href={cause.charity_url} target="_blank" rel="noopener" style={{ color: "#ff0202" }}>
                                                {cause.name}
                                            </Link>
                                        )}
                                        {!cause.charity_url && <div>{cause.name}</div>}
                                        <div className="ms-2">{cause.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row ml-2 mt-4 justify-content-center align-items-center">
                            <div className="col-5">
                                <label>Pledge amount ($)</label>
                            </div>
                            <div className="col-7">
                                <label>{gift.amount / 100}</label>
                            </div>
                        </div>
                        <div className="font-italic mt-4">
                            You can choose to pledge your own money in addition to the amount from the Pledge Card sent by {vidinvite.creator_user_first_name}. A team member from
                            the cause will follow up about your pledge.
                        </div>
                        {vidinvite.matching_charity && (
                            <div className="font-italic mt-2">
                                {vidinvite.matching_charity.match_percentage}% of your contribution will be matched by {vidinvite.matching_charity.name} (upto a maximum of $
                                {vidinvite.matching_charity.max_match_amount})
                            </div>
                        )}
                        <div className="row ml-2 justify-content-center align-items-center">
                            <div className="col-5">
                                <label>Your pledge ($)</label>
                            </div>
                            <div className="col-7 p-1">
                                <input
                                    className={"w-100 " + (individualAmountTouched && !individualAmount ? "error" : "")}
                                    placeholder="Enter your amount ($) (optional)"
                                    style={{ ...inputStyles, fontSize: "16px" }}
                                    type="number"
                                    value={individualAmount}
                                    onBlur={() => setIndividualAmountTouched(true)}
                                    onChange={evt => onChangeAmount(parseInt(`${evt.target.value}`))}
                                />
                            </div>
                        </div>
                        {vidinvite.matching_charity && individualAmount && calculatedMatchAmount && (
                            <>
                                <div className="row ml-2 mt-4 justify-content-center align-items-center">
                                    <div className="col-12">
                                        <label>{`$${gift.amount / 100} GivingJoy Card`}</label>
                                    </div>
                                </div>
                                <div className="row ml-2 mt-3 justify-content-center align-items-center">
                                    <div className="col-12">
                                        <label>{`$${individualAmount} Pledge added by you`}</label>
                                    </div>
                                </div>
                                <div className="row ml-2 mt-3 justify-content-center align-items-center">
                                    <div className="col-12">
                                        <label>{`$${calculatedMatchAmount} Pledge matched by ${vidinvite.matching_charity.name}`}</label>
                                    </div>
                                </div>
                                <div className="row ml-2 mt-3 justify-content-center align-items-center">
                                    <div className="col-12">
                                        <label>{`Total GivingJoy impact: $${totalAmount}`}</label>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row mt-5">
                            <div className="col">
                                <button type="submit" className="w-100 btn btn-primary" disabled={formIncomplete()} onClick={redeemGift}>
                                    Pledge
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default GivingjoyGiftRedemption;
