import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Error from "../../Common/Error";
import { useNavigate } from "react-router";
import validator from "validator";
import FileDrop from "../../Common/FileDrop";
import { AdminPaths } from "../../Common/AppRoutes";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { GenerateUniqueNumberWithoutUUID } from "../../Common/Helper";

const AddCause: FC = props => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState();
    const [disabled, setDisabled] = useState(false);
    const { register, formState, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            description: "",
            charityUrl: "",
            isSuggested: false
        },
        mode: "onChange"
    });

    const handleCreateCause = async (data: any) => {
        try {
            setDisabled(true);
            let causeImagesResponse;
            if (selectedFile) {
                setDisabled(true);
                const fileName = `charity-picture-${GenerateUniqueNumberWithoutUUID()}.png`;
                const formData = new FormData();
                formData.append("logo", new File([selectedFile], fileName, { type: "image/png" }));
                causeImagesResponse = await HttpService.postFormData(`/v1/cause/upload/images`, formData, true);
                if (causeImagesResponse.status !== 200) {
                    setDisabled(false);
                    ToastService.error("Unable to upload cause images");
                    return;
                }
            }
            const response = await HttpService.postFormData(`/v1/cause/create`, {
                name: data.name,
                description: data.description,
                charity_url: data.charityUrl,
                logo_url: causeImagesResponse ? causeImagesResponse.data.logo_key : "",
                is_suggested: data.isSuggested
            });
            setDisabled(false);
            if (response.status !== 200) {
                ToastService.error(response.message);
            } else {
                ToastService.success("Cause created successfully");
                navigate(AdminPaths.causes);
            }
        } catch (error) {
            setDisabled(false);
            ToastService.error("Something went wrong creating cause");
        }
    };

    const onSelectFile = (file: any) => {
        setSelectedFile(file);
    };

    const formIncomplete = () => {
        return !formState.isValid;
    };

    return (
        <div className="container pb-4 container-padding-bottom">
            <div className="text-center h2 mb-4 mt-4">Add Cause</div>
            <form className="card mt-3" onSubmit={handleSubmit(handleCreateCause)}>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Name</label>
                    <input
                        className={formState.errors.name && formState.touchedFields.name ? "error" : ""}
                        placeholder="Enter name (required)"
                        {...register("name", { required: true })}
                    />
                    {formState.errors.name && formState.touchedFields.name && <Error>Name is required</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Description</label>
                    <input
                        className={formState.errors.description && formState.touchedFields.description ? "error" : ""}
                        placeholder="Enter description (optional)"
                        {...register("description")}
                    />
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Cause URL &nbsp;</label>
                    <input
                        className={formState.errors.charityUrl && formState.touchedFields.charityUrl ? "error" : ""}
                        placeholder="Enter cause url (optional)"
                        {...register("charityUrl", {
                            validate: value => {
                                if (value) {
                                    return validator.isURL(value, {
                                        require_protocol: true
                                    });
                                }
                                return true;
                            },
                            onChange: event => (event.target.value = event.target.value.trim())
                        })}
                    />
                    {formState.errors?.charityUrl && formState.errors?.charityUrl?.type === "validate" && <Error>Invalid URL</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">
                        Picture (optional) &nbsp;
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Please upload only png format">
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </label>
                    <FileDrop onSelectFile={onSelectFile} selectedFile={selectedFile} upload_type="picture" />
                </div>
                <div className="row">
                    <div className="d-flex">
                        <FormControlLabel control={<Checkbox {...register("isSuggested")} />} label="Suggest this cause while creating gift card" />
                        <Tooltip
                            className="mt-2 ms-2"
                            enterTouchDelay={0}
                            leaveTouchDelay={4000}
                            onOpen={event => event.preventDefault()}
                            title="Suggested causes will show up while creating gift cards for One to Many VidInvite"
                        >
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </div>
                </div>
                <div className="mt-4 pt-2">
                    <button type="submit" className="w-100 btn btn-primary" disabled={formIncomplete() || disabled}>
                        Submit
                        {disabled && <i className="fa-spin fas fa-spinner ml-2"></i>}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddCause;
