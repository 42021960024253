import { useContext, Component } from "react";
import { useNavigate } from "react-router";
import { IUserInfo } from "../Interfaces/IUserInfo";
import { AuthContext } from "./AuthContext";
import { SocialLoginUserInfoMap } from "../Util/UserInfoMap";
import { AccountType } from "../Enums/AccountType";
import { BACKEND_URL } from "../Common/Constants";
import jwt from "jwt-decode";
import HttpService from "../Services/HttpService";
import ToastService from "../VideoLib/Common/ToastService";
import { Paths } from "../Common/AppRoutes";
import { EventService } from "../Services/EventService";
import { isUUID, LogValues } from "../Common/Helper";
import Splash from "../Components/Splash";
import { isGuestUser } from "../Common/GuardedRoute";
import { Role } from "../Enums/Role";

export interface AuthData {
    signIn: Function;
    signOut: Function;
    setUserData: Function;
    isLoggedInAdminUserSameAsLinkedToCompany: Function;
    guestLogin: Function;
    guestLogout: Function;
    user: IUserInfo | undefined;
    whitelistedEmails: undefined;
    vidinviteInfo: any;
    company: any;
    redirectPage: string | undefined;
}

interface AuthProps {
    navigate: any;
    children: React.ReactNode;
}

interface AuthState {
    user: IUserInfo | undefined;
    vidinviteInfo: any;
    redirectPage: string | undefined;
    company: any;
    whitelistedEmails: undefined;
    loading: boolean;
}

export class AuthProvider extends Component<AuthProps, AuthState> {
    state: AuthState = {
        user: undefined,
        vidinviteInfo: null,
        redirectPage: "",
        company: null,
        whitelistedEmails: undefined,
        loading: false
    };

    async componentDidMount() {
        this.setState({
            loading: true
        });
        this.setGuestInfo();
        this.setCompany();
        this.setParams();
        this.setRedirectUrl();

        let vidInviteInfo = null;
        if (localStorage.getItem("redirectUrl")) {
            vidInviteInfo = await this.getVidinviteInfo(localStorage.getItem("redirectUrl") || "");
            await this.getCompany();
        } else {
            if (sessionStorage.getItem("access_email_params")) {
                const params = JSON.parse(atob(sessionStorage.getItem("access_email_params") || "") || "{}");
                await this.getCompany(params.company_id);
            } else {
                await this.getCompany();
            }
            this.setState({
                vidinviteInfo: {}
            });
        }
        this.setToken();
        await this.setUserData(vidInviteInfo);
        this.setState({
            loading: false
        });
    }

    setGuestInfo = () => {
        sessionStorage.removeItem("guest_info");
        if (window.location.search.includes("guest_info")) {
            const queryParams = new URLSearchParams(window.location.search);
            let parsed = atob((queryParams.get("guest_info") || "").replace(" ", "+"));
            sessionStorage.setItem("guest_info", parsed);
            LogValues("AuthProvider - guest info block");
        }
    };

    setCompany = () => {
        if (window.location.search.includes("company")) {
            const queryParams = new URLSearchParams(window.location.search);
            const slug = queryParams.get("company");
            // logging out user only if logged in user company is different from the passed in url
            if (slug !== localStorage.getItem("slug")) {
                this.signOut();
            }
            if (slug) {
                localStorage.setItem("slug", slug);
                this.props.navigate(window.location.pathname || "/", { replace: true });
            }
            LogValues("AuthProvider - company block");
        }
    };

    setParams = () => {
        if (window.location.search.includes("params")) {
            if (!window.location.search.includes("redirectUrl") && !localStorage.getItem("redirectUrl")) {
                this.signOut();
            }
            const queryParams = new URLSearchParams(window.location.search);
            sessionStorage.setItem("access_email_params", queryParams.get("params") || "");
            if (window.location.search.includes("redirectUrl")) {
                localStorage.setItem("redirectUrl", queryParams.get("redirectUrl") || "");
            }
            LogValues("AuthProvider - Params block", {
                params: queryParams.get("params") || "",
                redirectUrl: queryParams.get("redirectUrl") || ""
            });
            this.props.navigate(Paths.emailOtpAccess, { replace: true });
        }
    };

    setRedirectUrl = () => {
        if (
            window.location.pathname !== "/" &&
            window.location.pathname !== "/processing" &&
            window.location.pathname !== "/privacy-policy" &&
            window.location.pathname !== "/terms" &&
            !window.location.pathname.startsWith("/email-access") &&
            !this.state.user
        ) {
            let redirectUrl = window.location.pathname;
            if (window.location.search.includes("vidinvite_email_data")) {
                redirectUrl += window.location.search;
            }
            localStorage.setItem("redirectUrl", redirectUrl);
            LogValues("AuthProvider - setting redirect url", {
                pathname: window.location.pathname
            });
        }
    };

    signIn = (accountType: AccountType) => {
        window.location.href = `${BACKEND_URL}/v1/auth/${accountType}?successRef=/processing&errorRef=/&companyId=${this.state.company?.id}`;
    };

    signOut = () => {
        this.setState({
            user: undefined
        });
        localStorage.removeItem("userData");
        localStorage.removeItem("redirectUrl");
        sessionStorage.removeItem("access_email_params");
        LogValues("AuthProvider - sign out block");
        this.props.navigate(Paths.landing);
    };

    setUserData = async (vidInviteInfo: any = null) => {
        // logging out if the user is guest user
        if (isGuestUser(JSON.parse(localStorage.getItem("userData") || "{}"))) {
            this.guestLogout();
        }
        if (localStorage.getItem("userData")) {
            const token = JSON.parse(localStorage.getItem("userData") || "").token;
            let decodedToken: any;
            if (token) {
                decodedToken = jwt(token as string);
            }
            const time = new Date().getTime();
            if (!decodedToken) {
                // bug fix: log out user if token is invalid
                // do not call sign out function because it will clear redirect url as well which will prevent showing logging popup
                this.setState({
                    user: undefined
                });
                localStorage.removeItem("userData");
                localStorage.setItem("redirectUrl", window.location.pathname);
                LogValues("AuthProvider - decoded token null or undefined", {
                    pathname: window.location.pathname
                });
            } else if (decodedToken && decodedToken.exp * 1000 < time) {
                // bug fix: log out user if token is expired
                // do not call sign out function because it will clear redirect url as well which will prevent showing logging popup
                this.setState({
                    user: undefined
                });
                localStorage.removeItem("userData");
                LogValues("AuthProvider - decoded token expired", {
                    pathname: window.location.pathname
                });
                if (window.location.pathname !== "/" && window.location.pathname !== "") {
                    localStorage.setItem("redirectUrl", window.location.pathname);
                    LogValues("AuthProvider - decoded token expired and url not equal to / and empty", {
                        pathname: window.location.pathname
                    });
                }
                if (window.location.pathname.startsWith("/celebration") || window.location.pathname.startsWith("/contribute")) {
                    if (vidInviteInfo && vidInviteInfo.is_allow_anyone_contribute) {
                        LogValues("AuthProvider - guest login triggering from condition 1");
                        await this.guestLogin();
                    } else {
                        LogValues("AuthProvider - redirect to landing page if is allow flag is not enabled");
                        ToastService.error("Please log in to view VidInvite!");
                        this.props.navigate(Paths.landing, { replace: true });
                    }
                }
            } else {
                LogValues("AuthProvider - user data else block");
                this.setState({
                    user: JSON.parse(localStorage.getItem("userData") || "")
                });
            }
        } else if (window.location.pathname.startsWith("/celebration") || window.location.pathname.startsWith("/contribute")) {
            if (vidInviteInfo && vidInviteInfo.is_allow_anyone_contribute) {
                LogValues("AuthProvider - path starts with celebration or contribute");
                LogValues("AuthProvider - guest login triggering from condition 2");
                await this.guestLogin();
            } else {
                LogValues("AuthProvider - redirect to landing page if is allow flag is not enabled");
                ToastService.error("Please log in to view VidInvite!");
                this.props.navigate(Paths.landing, { replace: true });
            }
        }
    };

    setToken = () => {
        if (window.location.search.includes("token")) {
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get("token") as string;
            let decodedToken: any;
            if (token) {
                decodedToken = jwt(queryParams.get("token") as string);
            }
            const userData: IUserInfo = SocialLoginUserInfoMap(
                decodedToken.email,
                decodedToken.userId,
                decodedToken.companyId,
                decodedToken.name,
                decodedToken.accountType,
                token,
                decodedToken.role,
                decodedToken.phoneNumber
            );
            this.setState({
                user: userData
            });
            EventService.trackUser(userData.email);
            localStorage.setItem("userData", JSON.stringify(userData));
            LogValues("AuthProvider - token block");
            this.isLoggedInAdminUserSameAsLinkedToCompany(userData);
            this.props.navigate(localStorage.getItem("redirectUrl") || "/", { replace: true });
            localStorage.removeItem("redirectUrl");
        }
    };

    getCompany = async (companyId: any = null) => {
        if (companyId) {
            try {
                const response = await HttpService.getWithAPIKey(`/v1/company/${companyId}`);
                if (response.company) {
                    // bug fix: logout if user enters url which belongs to different company
                    // do not call sign out function because it will clear redirect url as well which will prevent showing logging popup
                    if (localStorage.getItem("slug") !== response.company.slug_name) {
                        this.setState({
                            user: undefined
                        });
                        localStorage.removeItem("userData");
                        this.props.navigate(localStorage.getItem("redirectUrl") || "/", { replace: true });
                    }
                    localStorage.setItem("slug", response.company.slug_name);
                    await this.getWhitelistedEmails(response.company.id);
                    this.setState({
                        company: response.company
                    });
                }
            } catch (error) {
                ToastService.error("Invalid Company");
            }
        } else {
            const slug = localStorage.getItem("slug") || "vidinvite";
            try {
                const response = await HttpService.getWithAPIKey(`/v1/company/slug/${slug}`);
                if (response.company) {
                    await this.getWhitelistedEmails(response.company.id);
                    localStorage.setItem("slug", response.company.slug_name);
                    this.setState({
                        company: response.company
                    });
                }
            } catch (error) {
                ToastService.error("Invalid Company");
                localStorage.setItem("slug", "vidinvite");
                try {
                    const response = await HttpService.getWithAPIKey(`/v1/company/slug/vidinvite`);
                    if (response.company) {
                        await this.getWhitelistedEmails(response.company.id);
                        this.setState({
                            company: response.company
                        });
                    }
                } catch (error) {
                    ToastService.error("Invalid Company");
                    this.signOut();
                }
            }
        }
    };

    getVidinviteInfo = async (redirectUrl: string) => {
        if (redirectUrl && redirectUrl !== "undefined") {
            const redirectPage = redirectUrl.split("/")[1];
            this.setState({
                redirectPage
            });
            let eventId = redirectUrl.split("/")[2];
            if (sessionStorage.getItem("guest_info") && sessionStorage.getItem("guest_info") !== "undefined") {
                eventId = JSON.parse(sessionStorage.getItem("guest_info") || "").vidinvite_id;
            }
            if (eventId && (!isNaN(parseInt(eventId)) || isUUID(eventId))) {
                try {
                    const response = await HttpService.getWithAPIKey(`/v1/vidinvite/info/${eventId}`);
                    if (response.vidinvite) {
                        this.setState({
                            vidinviteInfo: response.vidinvite
                        });
                        await this.getCompany(response.vidinvite.company_id);
                        return response.vidinvite;
                    } else {
                        ToastService.error("Vidinvite not found!");
                    }
                } catch (error) {
                    this.setState({
                        vidinviteInfo: {}
                    });
                }
            } else {
                this.setState({
                    vidinviteInfo: {}
                });
            }
        }
    };

    getWhitelistedEmails = async (companyId: any) => {
        const response = await HttpService.getWithAPIKey(`/v1/whitelistedemails/company/${companyId}`);
        try {
            if (response.emails) {
                this.setState({
                    whitelistedEmails: response.emails.map((whitelistedEmail: any) => whitelistedEmail.email)
                });
            }
        } catch (error) {}
    };

    guestLogin = async () => {
        if (!this.state.user) {
            try {
                const slug = localStorage.getItem("slug") || "vidinvite";
                const response = await HttpService.postFormData(`/v1/guest/login/${slug}`, {}, false, true);
                if (response) {
                    const token = response.token;
                    let decodedToken: any;
                    if (token) {
                        decodedToken = jwt(token);
                    }
                    const userData = SocialLoginUserInfoMap(
                        decodedToken.email,
                        decodedToken.userId,
                        decodedToken.companyId,
                        decodedToken.name,
                        decodedToken.accountType,
                        token,
                        decodedToken.role,
                        decodedToken.phoneNumber
                    );
                    this.setState({
                        user: userData
                    });
                    localStorage.setItem("userData", JSON.stringify(userData));
                    LogValues("AuthProvider - guest login block");
                    LogValues("Clearing redirect url to avoid login popup if user visits any other page");
                    localStorage.removeItem("redirectUrl");
                } else {
                    ToastService.error("Unable to login with guests");
                }
            } catch (error) {
                ToastService.error("Unable to login with guests");
            }
        }
    };

    isLoggedInAdminUserSameAsLinkedToCompany = (user: any) => {
        if ((user.role === Role.ADMIN || user.role === Role.SUPER_ADMIN) && user.userId !== this.state.company.admin_user_id) {
            // logging out admin user if logged in admin user not assigned to company
            ToastService.error(`Please login with ${this.state.company.admin_user_email} to access Admin portal for ${this.state.company.name} company`);
            this.signOut();
            return false;
        }
        return true;
    };

    setLoggedInUser = (user: any) => {
        this.setState({
            user
        });
    };

    guestLogout = () => {
        this.setState({
            user: undefined
        });
        localStorage.removeItem("userData");
        LogValues("AuthProvider - guest logout block");
    };

    render() {
        if (this.state.loading) {
            return <Splash />;
        }
        const authData: AuthData = {
            signIn: this.signIn,
            signOut: this.signOut,
            guestLogin: this.guestLogin,
            guestLogout: this.guestLogout,
            setUserData: this.setLoggedInUser,
            isLoggedInAdminUserSameAsLinkedToCompany: this.isLoggedInAdminUserSameAsLinkedToCompany,
            user: this.state.user,
            company: this.state.company,
            whitelistedEmails: this.state.whitelistedEmails,
            redirectPage: this.state.redirectPage,
            vidinviteInfo: this.state.vidinviteInfo
        };
        return <AuthContext.Provider value={authData}>{this.props.children}</AuthContext.Provider>;
    }
}

export const WrappedAuthProvider = (props: any) => {
    const navigate = useNavigate();
    return <AuthProvider navigate={navigate}>{props.children}</AuthProvider>;
};

export function useAuth() {
    return useContext(AuthContext);
}
