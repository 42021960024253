import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Error from "../../Common/Error";
import { useNavigate, useParams } from "react-router";
import FileDrop from "../../Common/FileDrop";
import { AdminPaths } from "../../Common/AppRoutes";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { GenerateUniqueNumberWithoutUUID } from "../../Common/Helper";
import Splash from "../Splash";

const EditCause: FC = props => {
    const navigate = useNavigate();
    const [selectedCause, setSelectedCause] = useState<any>(null);
    const { causeId } = useParams();
    const [selectedFile, setSelectedFile] = useState();
    const [cancelClickedSelectedFile, setCancelClickedSelectedFile] = useState<boolean>();
    const [disabled, setDisabled] = useState(false);
    const { register, formState, setValue, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            description: "",
            charityUrl: "",
            isSuggested: false
        },
        mode: "onChange"
    });

    useEffect(() => {
        const getCause = () => {
            HttpService.get(`/v1/cause/${causeId}`).then(async response => {
                if (response.cause) {
                    setSelectedCause(response.cause);
                    setValue("name", response?.cause?.name, { shouldValidate: true });
                    setValue("description", response?.cause?.description, { shouldValidate: true });
                    setValue("charityUrl", response?.cause?.charity_url, { shouldValidate: true });
                    setValue("isSuggested", response?.cause?.is_suggested);
                } else {
                    ToastService.error("Cause not found!");
                }
            });
        };
        if (!selectedCause) {
            getCause();
        }
    }, [causeId, selectedCause, setValue]);

    const handleEditCause = async (data: any) => {
        try {
            setDisabled(true);
            const formData = new FormData();
            if (selectedFile) {
                formData.append("logo", new File([selectedFile], `charity-picture-${GenerateUniqueNumberWithoutUUID()}.png`, { type: "image/png" }));
            }
            let causeImagesResponse;
            if (formData.has("logo")) {
                causeImagesResponse = await HttpService.postFormData(`/v1/cause/upload/images`, formData, true);
                if (causeImagesResponse.status !== 200) {
                    setDisabled(false);
                    ToastService.error("Unable to upload cause images");
                    return;
                }
            }
            const response = await HttpService.putFormData(`/v1/cause/${causeId}`, {
                name: data.name,
                description: data.description,
                charity_url: data.charityUrl,
                logo_url: cancelClickedSelectedFile ? "" : selectedFile ? causeImagesResponse.data.logo_key : selectedCause?.logo_url,
                is_suggested: data.isSuggested
            });
            setDisabled(false);
            if (response.status !== 200) {
                ToastService.error(response.message);
            } else {
                ToastService.success("Cause updated successfully");
                navigate(AdminPaths.causes);
            }
        } catch (error) {
            setDisabled(false);
            ToastService.error("Something went wrong updating cause");
        }
    };

    const onSelectFile = (file: any) => {
        !file ? setCancelClickedSelectedFile(true) : setCancelClickedSelectedFile(false);
        setSelectedFile(file);
    };

    const formIncomplete = () => {
        return !formState.isValid;
    };

    if (!selectedCause) {
        return <Splash />;
    }
    return (
        <div className="container pb-4 container-padding-bottom">
            <div className="text-center h2 mb-4 mt-4">Edit Cause</div>
            <form className="card mt-3" onSubmit={handleSubmit(handleEditCause)}>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Name</label>
                    <input
                        className={formState.errors.name && formState.touchedFields.name ? "error" : ""}
                        placeholder="Enter name (required)"
                        {...register("name", { required: true })}
                    />
                    {formState.errors.name && formState.touchedFields.name && <Error>Name is required</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Description</label>
                    <input
                        className={formState.errors.description && formState.touchedFields.description ? "error" : ""}
                        placeholder="Enter description (optional)"
                        {...register("description")}
                    />
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Cause URL &nbsp;</label>
                    <input
                        className={formState.errors.charityUrl && formState.touchedFields.charityUrl ? "error" : ""}
                        placeholder="Enter cause url (optional)"
                        {...register("charityUrl")}
                    />
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">
                        Logo (optional) &nbsp;
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Please upload only png format">
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </label>
                    <FileDrop
                        onSelectFile={onSelectFile}
                        selectedFile={selectedFile}
                        cropSize={{ width: 172, height: 59 }}
                        upload_type="logo"
                        url={selectedCause?.logo_url}
                        accepted_formats="image/png"
                    />
                </div>
                <div className="row">
                    <div className="d-flex">
                        <FormControlLabel
                            control={<Checkbox defaultChecked={selectedCause?.is_suggested} {...register("isSuggested")} />}
                            label="Suggest this cause while creating gift card"
                        />
                        <Tooltip
                            className="mt-2 ms-2"
                            enterTouchDelay={0}
                            leaveTouchDelay={4000}
                            onOpen={event => event.preventDefault()}
                            title="Suggested causes will show up while creating gift cards for One to Many VidInvite"
                        >
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </div>
                </div>
                <div className="mt-4 pt-2">
                    <button type="submit" className="w-100 btn btn-primary" disabled={formIncomplete() || disabled}>
                        Submit
                        {disabled && <i className="fa-spin fas fa-spinner ml-2"></i>}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditCause;
