import { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./CanvasUtils";

const DialogContainer = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const CropContainer = styled(Box)`
    position: relative;
    min-height: 240px;
    min-width: 240px;
    max-width: 500px;
    width: calc(100vw - 2.4rem);
    height: 50vh;
`;

const CropBox = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export interface CropImageProps {
    file: File;
    isOpen: boolean;
    aspectRatio?: number;
    cropSize?: { width: number; height: number };
    onSubmitCropImage: (file: File) => void;
    handleClose: () => void;
}

const CropImage = (props: CropImageProps) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCancel = () => {
        props.handleClose();
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onSubmit = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(props.file ? URL.createObjectURL(props.file) : "", croppedAreaPixels, rotation);
            props.onSubmitCropImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [props, croppedAreaPixels, rotation]);

    return (
        <DialogContainer open={props.isOpen}>
            <DialogTitle sx={{ m: "0 auto", p: 2, fontSize: "1.5rem" }}>
                Crop your image
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    size="large"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 14,
                        color: "#2c88d9"
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CropContainer>
                    <CropBox>
                        <Cropper
                            image={props.file ? URL.createObjectURL(props.file) : ""}
                            crop={crop}
                            zoom={zoom}
                            aspect={props.aspectRatio || 16 / 9}
                            cropSize={props.cropSize || undefined}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </CropBox>
                </CropContainer>
            </DialogContent>
            <DialogActions sx={{ m: "0 auto" }}>
                <div className="pb-2 gap-2 d-sm-block d-grid col-12 crop-buttons">
                    <button type="button" className="btn btn-primary crop-image-button col-xs-12" onClick={onSubmit}>
                        Continue
                    </button>
                    <button type="button" className="btn btn-outline-primary cancel-button col-xs-12" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </DialogActions>
        </DialogContainer>
    );
};

export default CropImage;
