import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { useAuth } from "../Contexts/AuthProvider";
import { Role } from "../Enums/Role";
import { Paths } from "./AppRoutes";
import { AuthContext } from "../Contexts/AuthContext";

export const isWhitelistedUser = (user: any, whitelistedEmails: any = []): boolean => {
    if (user) {
        return (whitelistedEmails || []).indexOf(user.email) >= 0;
    } else {
        return false;
    }
};

export const isAdminUser = (user: any): boolean => {
    if (user) {
        return user.role === Role.SUPER_ADMIN || user.role === Role.ADMIN;
    } else {
        return false;
    }
};

export const isGuestUser = (user: any): boolean => {
    if (user) {
        return user.role === Role.GUEST;
    } else {
        return false;
    }
};

export const customAuthenticatorByRoutes = (company: any, user: any, path: string, whitelistedEmails: any): boolean => {
    if (!user) {
        return false;
    }
    if (isGuestUser(user)) {
        return false;
    }
    if (isAdminUser(user)) {
        return true;
    }
    if (!isAdminUser(user) && path.startsWith("/admin")) {
        return false;
    }
    // if (company?.id !== 1) {
    //     return true;
    // }
    if (path.startsWith(Paths.createVidInvite)) {
        return isWhitelistedUser(user, whitelistedEmails);
    }
    return true;
};

export const GuardedRoute = ({ children }: any) => {
    const { user, whitelistedEmails } = useAuth();
    const location = useLocation();
    const authContext = useContext(AuthContext);

    return customAuthenticatorByRoutes(authContext.company, user, location.pathname, whitelistedEmails) ? (
        children
    ) : (
        <Navigate to={Paths.landing} replace state={{ path: location.pathname }} />
    );
};
