import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import HttpService from "../../Services/HttpService";
import ToastService from "../../VideoLib/Common/ToastService";
import Error from "../../Common/Error";
import { useNavigate } from "react-router";
import FileDrop from "../../Common/FileDrop";
import { AdminPaths } from "../../Common/AppRoutes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GenerateUniqueNumberWithoutUUID, isJsonString } from "../../Common/Helper";

const AddCompany: FC = props => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedEmailLogoFile, setSelectedEmailLogoFile] = useState();
    const [selectedHeroImageFile, setSelectedHeroImageFile] = useState();
    const [disabled, setDisabled] = useState(false);
    const { register, formState, handleSubmit } = useForm({
        defaultValues: {
            name: "",
            description: "",
            slug_name: "",
            promo_text: "",
            invite_text: "",
            one_to_many_vidinvite_description: "",
            many_to_one_vidinvite_description: "",
            hero_title: "",
            hero_sub_title: "",
            admin_user_email: "",
            admin_user_name: "",
            landing_bubble_top_left: "",
            landing_bubble_top_right: "",
            landing_bubble_bottom_left: "",
            landing_bubble_bottom_right: "",
            howitworks_left_image: "",
            howitworks_middle_image: "",
            howitworks_right_image: "",
            guest_terminology: "",
            hide_featured_vidinvites: false,
            hide_bubbles: false,
            hide_giftcards: false
        },
        mode: "onChange"
    });

    const handleCreateCompany = async (data: any) => {
        try {
            if (selectedFile && selectedEmailLogoFile) {
                setDisabled(true);
                const fileName = `logo-${data.slug_name}-${GenerateUniqueNumberWithoutUUID()}.png`;
                const formData = new FormData();
                formData.append("logo", new File([selectedFile], fileName, { type: "image/png" }));
                if (selectedEmailLogoFile) {
                    formData.append(
                        "email-logo",
                        new File([selectedEmailLogoFile], `email-logo-${data.slug_name}-${GenerateUniqueNumberWithoutUUID()}.png`, { type: "image/png" })
                    );
                }
                if (selectedHeroImageFile) {
                    formData.append("hero", new File([selectedHeroImageFile], `hero-${data.slug_name}-${GenerateUniqueNumberWithoutUUID()}.png`, { type: "image/png" }));
                }
                const companyImagesResponse = await HttpService.postFormData(`/v1/company/upload/images`, formData, true);
                if (companyImagesResponse.status === 200) {
                    const response = await HttpService.postFormData(`/v1/company/create`, {
                        name: data.name,
                        description: data.description,
                        slug_name: data.slug_name,
                        logo: companyImagesResponse ? companyImagesResponse.data.logo_key : null,
                        email_logo: companyImagesResponse ? companyImagesResponse.data.email_logo_key : null,
                        admin_user_email: data.admin_user_email,
                        admin_user_name: data.admin_user_name,
                        contents: {
                            hero_image: companyImagesResponse.data.hero_key ? companyImagesResponse.data.hero_key : null,
                            promo_title_text: data.promo_text || "",
                            invite_text: data.invite_text || "",
                            one_to_many_vidinvite_description: data.one_to_many_vidinvite_description || "",
                            many_to_one_vidinvite_description: data.many_to_one_vidinvite_description || "",
                            hero_title: data.hero_title || "",
                            hero_sub_title: data.hero_sub_title || "",
                            landing_bubble_top_left: data.landing_bubble_top_left || "",
                            landing_bubble_top_right: data.landing_bubble_top_right || "",
                            landing_bubble_bottom_left: data.landing_bubble_bottom_left || "",
                            landing_bubble_bottom_right: data.landing_bubble_bottom_right || "",
                            howitworks_left_image: data.howitworks_left_image || "",
                            howitworks_middle_image: data.howitworks_middle_image || "",
                            howitworks_right_image: data.howitworks_right_image || "",
                            guest_terminology: data.guest_terminology || "",
                            hide_featured_vidinvites: data.hide_featured_vidinvites || false,
                            hide_giftcards: data.hide_giftcards || false,
                            hide_bubbles: data.hide_bubbles || false
                        }
                    });
                    setDisabled(false);
                    if (response.status !== 200) {
                        ToastService.error(response.message);
                    } else {
                        ToastService.success("Company created successfully");
                        navigate(AdminPaths.companies);
                    }
                } else {
                    setDisabled(false);
                    ToastService.error("Unable to upload company images");
                }
            }
        } catch (error: any) {
            setDisabled(false);
            const errorObject = isJsonString(error.message) ? JSON.parse(error.message) : null;
            if (errorObject) {
                ToastService.error(errorObject.message);
            } else {
                ToastService.error("Something went wrong creating company");
            }
        }
    };

    const onSelectFile = (file: any) => {
        setSelectedFile(file);
    };

    const onSelectEmailLogoFile = (file: any) => {
        setSelectedEmailLogoFile(file);
    };

    const onSelectHeroImageFile = (file: any) => {
        setSelectedHeroImageFile(file);
    };

    const formIncomplete = () => {
        if (formState.isValid && (!selectedFile || !selectedEmailLogoFile)) {
            return true;
        }
        return !formState.isValid;
    };

    return (
        <div className="container pb-4 container-padding-bottom">
            <div className="text-center h2 mb-4 mt-4">Add Company</div>
            <form className="card mt-3" onSubmit={handleSubmit(handleCreateCompany)}>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Name</label>
                    <input
                        className={formState.errors.name && formState.touchedFields.name ? "error" : ""}
                        placeholder="Enter name (required)"
                        {...register("name", { required: true })}
                    />
                    {formState.errors.name && formState.touchedFields.name && <Error>Name is required</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Description</label>
                    <input
                        className={formState.errors.description && formState.touchedFields.description ? "error" : ""}
                        placeholder="Enter description (optional)"
                        {...register("description")}
                    />
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">
                        Slug &nbsp;
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="This will be used to generate company URL">
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </label>
                    <input
                        className={formState.errors.slug_name && formState.touchedFields.slug_name ? "error" : ""}
                        placeholder="Enter slug (required)"
                        {...register("slug_name", {
                            required: true,
                            onChange: event => (event.target.value = event.target.value.trim().toLowerCase())
                        })}
                    />
                    {formState.errors.slug_name?.type === "required" && formState.touchedFields.slug_name && <Error>Slug is required</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Admin user email</label>
                    <input
                        className={formState.errors.admin_user_email && formState.touchedFields.admin_user_email ? "error" : ""}
                        placeholder="Enter admin user email (required)"
                        {...register("admin_user_email", {
                            required: true,
                            pattern:
                                /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            onChange: event => (event.target.value = event.target.value.trim())
                        })}
                    />
                    {formState.errors.admin_user_email?.type === "required" && <Error>Admin user email is required</Error>}
                    {formState.errors.admin_user_email?.type === "pattern" && <Error>Please enter a valid email</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">Admin user name</label>
                    <input
                        className={formState.errors.admin_user_name && formState.touchedFields.admin_user_name ? "error" : ""}
                        placeholder="Enter admin user name (required)"
                        {...register("admin_user_name", { required: true })}
                    />
                    {formState.errors.admin_user_name && formState.touchedFields.admin_user_name && <Error>Admin user name is required</Error>}
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">
                        Logo (required) &nbsp;
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Please upload only png format">
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </label>
                    <FileDrop onSelectFile={onSelectFile} selectedFile={selectedFile} cropSize={{ width: 172, height: 59 }} upload_type="logo" accepted_formats="image/png" />
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="form-label">
                        Email Logo (required) &nbsp;
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Please upload only png format">
                            <i className="fas fa-question-circle"></i>
                        </Tooltip>
                    </label>
                    <FileDrop
                        onSelectFile={onSelectEmailLogoFile}
                        selectedFile={selectedEmailLogoFile}
                        cropSize={{ width: 88, height: 88 }}
                        upload_type="email logo"
                        accepted_formats="image/png"
                    />
                </div>
                <div className="row mb-3 align-items-center">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">Brand messaging:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Hero title &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="This will be displayed on top of the landing page">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input placeholder="Enter title (optional)" {...register("hero_title")} />
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Hero sub-title &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="This will be displayed under the Hero-title on the landing page">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input placeholder="Enter sub-title (optional)" {...register("hero_sub_title")} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">Hero image & messaging:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Hero Image (optional) &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Please upload jpg, webp or png format">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <FileDrop
                                    onSelectFile={onSelectHeroImageFile}
                                    selectedFile={selectedHeroImageFile}
                                    upload_type="hero image"
                                    disable_crop={true}
                                    accepted_formats={["image/png", "image/jpeg", "image/jpg", "image/webp"]}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    Landing Bubbles: &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="Change the text of the bubbles shown floating on the hero image on the landing page">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Top-Left)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("landing_bubble_top_left")} />
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Top-Right)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("landing_bubble_top_right")} />
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Bottom-Left)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("landing_bubble_bottom_left")} />
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Bottom-Right)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("landing_bubble_bottom_right")} />
                                </div>
                            </div>
                            <div className="row mb-3 align-items-center">
                                <FormControlLabel control={<Checkbox id="hide_bubbles" {...register("hide_bubbles")} />} label="Hide bubbles on landing page" />
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Promo Text &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="This will be displayed as overlay on the hero image">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input
                                    className={formState.errors.promo_text && formState.touchedFields.promo_text ? "error" : ""}
                                    placeholder="Enter promo text (optional)"
                                    {...register("promo_text")}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">Call to action:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Create vidinvite one to many description &nbsp;
                                    <Tooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={4000}
                                        title="This will be displayed below the description of one to many in create vidinvite popup"
                                    >
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input
                                    className={formState.errors.one_to_many_vidinvite_description && formState.touchedFields.one_to_many_vidinvite_description ? "error" : ""}
                                    placeholder="Enter one to many vidinvite description (optional)"
                                    {...register("one_to_many_vidinvite_description")}
                                />
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Create vidinvite many to one description &nbsp;
                                    <Tooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={4000}
                                        title="This will be displayed below the description of many to one in create vidinvite popup"
                                    >
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input
                                    className={formState.errors.many_to_one_vidinvite_description && formState.touchedFields.many_to_one_vidinvite_description ? "error" : ""}
                                    placeholder="Enter many to one vidinvite description (optional)"
                                    {...register("many_to_one_vidinvite_description")}
                                />
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Invite Text &nbsp;
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} title="This will be displayed below the promo text section">
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input
                                    className={formState.errors.invite_text && formState.touchedFields.invite_text ? "error" : ""}
                                    placeholder="Enter invite text (optional)"
                                    {...register("invite_text")}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">How it works:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <div className="col-12">
                                    Animated text below image: &nbsp;
                                    <Tooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={4000}
                                        title="Change the animated text below the image in how it works section on the landing page."
                                    >
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Left-Image-Text)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("howitworks_left_image")} />
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Middle-Image-Text)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("howitworks_middle_image")} />
                                </div>
                                <div className="col-lg-6 col-md-12 align-items-center mt-2">
                                    <div className="form-label">(Right-Image-Text)</div>
                                    <input className="w-100" placeholder="Enter text (optional)" {...register("howitworks_right_image")} />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">Features:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <FormControlLabel
                                    control={<Checkbox id="hide_featured_vidinvites" {...register("hide_featured_vidinvites")} />}
                                    label="Hide featured VidInvites section on landing page"
                                />
                            </div>
                            <div className="row mb-3 align-items-center">
                                <FormControlLabel
                                    control={<Checkbox id="hide_giftcards" {...register("hide_giftcards")} />}
                                    label="Hide Pledge and Gift cards on create vidinvite flow"
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row mb-3 align-items-center">
                    <Accordion className="p-0 w-100 mt-4 no-shadow border">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="h5">Site configuration:</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mb-3 align-items-center">
                                <label className="form-label">
                                    Guest terminology &nbsp;
                                    <Tooltip
                                        enterTouchDelay={0}
                                        leaveTouchDelay={4000}
                                        title="This will change wherever guest word appears across site. Please don't add plural value."
                                    >
                                        <i className="fas fa-question-circle"></i>
                                    </Tooltip>
                                </label>
                                <input placeholder="Enter guest terminology (optional)" {...register("guest_terminology")} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="mt-4 pt-2">
                    <button type="submit" className="w-100 btn btn-primary" disabled={formIncomplete() || disabled}>
                        Submit
                        {disabled && <i className="fa-spin fas fa-spinner ml-2"></i>}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddCompany;
